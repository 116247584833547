import * as React from "react"
import MainLayout from "../components/layouts/main-layout"
import ProductHeader from "../components/ui/product-header"
import ProductCard from "../components/ui/product-card"
import useProducts from "../hooks/use-products"
import useCategories from "../hooks/use-categories"
import useCompany from "../hooks/use-company"

const Product = ({ pageContext }) => {
  const { id } = pageContext
  const product = useProducts().getProduct(id)
  const categories = useCategories().getCategoryIncludes(product.categories.map(({id}) => (id)))
  const images = [product.image.url]
  if (product.images.length) {
    images.push(...product.images.map(({ url }) => (url)))
  }
  const company = useCompany().get()
  const _title = `${company.seo.title} | ${product.seo.title || product.name}`
  const _description = product.seo.description || `${product.name} ${company.seo.description}`
  const _keywords = product.seo.keywords

  return (
    <MainLayout title={_title} description={_description} keywords={_keywords}>
      <ProductHeader image={product.image} images={images} name={product.name} excerpt={product.excerpt} file={product.file} tags={product.tags} categories={categories} />
      <section className="container">
        <article className="py-4 px-3" dangerouslySetInnerHTML={{ __html: product.description }}></article>
      </section>
      <section className="container py-5">
        <h3 className="fs-4 fw-bold pt-3 pb-2">Productos relacionados</h3>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 g-3 mt-2">
          {product?.related && product?.related?.map(({ id, name, excerpt, slug, image }) => (
            <div className="col" key={id}>
              <ProductCard name={name} excerpt={excerpt} slug={slug} image={image} />
            </div>
          ))}
        </div>
      </section>
    </MainLayout>
  )
}

export default Product