import * as React from "react"
import { Link } from "gatsby"
import Carousel from "./carousel"

const ProductHeader = ({ name, excerpt, file, tags, images, categories }) => (
  <section className="bg-light">
    <article className="container">
      <div className="row d-flex justify-content-center py-5 mb-4 category-header">
        <div className="col-sm-12 col-lg-5 col-xl-6">
          <div className="mt-5">
            <p className="my-1">
              {categories && categories.map((c, i) => (
                <Link key={i} to={`/categoria/${c.slug}`} className="link-secondary me-2"> / {c.name}</Link>
              ))}
            </p>
            <h1>{name}</h1>
            <p className="fs-4 text-muted pe-1 pe-xl-5">{excerpt}</p>
            <p className="mb-3">
              {tags && tags.map(tag => (
                <span className="badge badge-primary me-2 bg-default text-dark" title={tag.name} key={tag.name}>{tag.name}</span>
              ))}
            </p>
            {file && file?.url && (
              <p className="mt-5 border-top border-bottom py-2">
                <a href={file.url} target="_blank"  rel="noreferrer" className="btn btn-lg bg-files shadow-none rounded-0 text-light">
                  <span className="icon icon-download-cloud text-light"></span> Descargar manual PDF
                </a>
              </p>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-lg-7 col-xl-6 position-relative animate__animated animate__fadeIn">
          <div className="category-image right">
            <Carousel images={images} name={name} />
          </div>
          <div className="category-shadow right bg-default shadow animate__animated animate__fadeIn animate_slower"></div>
        </div>
      </div>
    </article>
  </section>
)

export default ProductHeader